import React from 'react'
import PropTypes from 'prop-types'
import { graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const JobPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  joblink
}) => {
  const JobContent = contentComponent || Content
  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-6 is-offset-1">
            <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h2>
            <p>{description}</p>
            <JobContent content={content} />
          </div>
        </div>
            <div className="columns">
            <div className="column is-6 is-offset-1">
                <a className="button" href={''+joblink}>Apply Now</a>
            </div>
        </div>
      </div>
    </section>
  )
}

JobPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  joblink: PropTypes.string,
  title: PropTypes.string
}

const JobPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <JobPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        joblink={post.frontmatter.joblink}
        />
    </Layout>
  )
}

JobPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default JobPost

export const pageQuery = graphql`
  query JobPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        joblink
      }
    }
  }
`
